import HomeScreen from "./screens/HomeScreen";
import { NavBarContextProvider } from "./context/cssContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ApplyScreen from "./screens/ApplyScreen";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsAndService from "./screens/TermsAndService";

function App() {
  return (
    <>
      <NavBarContextProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<HomeScreen />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route path="/TermsAndService" element={<TermsAndService />}></Route>
            <Route path="/jobs" element={<ApplyScreen />}></Route>
          </Routes>
        </BrowserRouter>
      </NavBarContextProvider>
    </>
  );
}

export default App;
