import React from "react";
import TopHeader from "../components/TopHeader";
import Footer from "../components/Footer";

const listItemStyles = {
  marginBottom: '10px',
  backgroundColor: '#f2f2f2', // Background color for list items
  padding: '8px', // Add some padding to the list items
  borderRadius: '5px', // Add rounded corners to the list items
  display: 'flex', // Use flex display for better spacing control
  alignItems: 'center', // Center the content vertically
};
const bulletPointStyles = {
  marginRight: '10px',
};

const linkStyles = {
  color: 'blue',
  textDecoration: 'underline',
};
const PrivacyPolicy = () => {
  return (
    <>
      <TopHeader />
      <div className="section featured-game" style={{ backGroundColor: 'white', zIndex: 10 }}>
        <style type="text/css"></style>
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <h1 class="entry__title">Privacy Policy</h1>
              <article id="post-17" class="entry post-17 page type-page status-publish hentry">
                <div class="entry__content">
                <h3>Welcome to Privacy Policy!</h3>
                  <p>The collection and processing of your personal data are described in this privacy 
statement. We periodically update this privacy statement. If we make changes, 
we'll let you know by updating the policy's date at the top. Depending on the 
specific changes, we may also give you more notice or ask for your approval 
once more. Every time you use the App, we encourage you to read the Privacy 
Policy so that you are aware of how we handle your personal data and how you 
may safeguard your privacy</p>
                  <h3>Information and Data we collected from you directly</h3>
                  <p>You can directly provide us with your contact information when you use our 
Services, which usually consists of your name and email address. We also 
gather certain information and data, including your application usage, access, 
session lengths, and in-app purchases, as well as your hardware type, operating 
system version, device name, name, email address, and cover photos (if you've 
linked your Facebook or Google+ accounts), as well as your general 
(city/country) location (based on your Internet Protocol ("IP") address).</p>
                  <p>In order to track your usage of our Service, we also generate a special user ID. 
To keep track of the games you are playing, this special user ID is saved along 
with your profile data. Occasionally, we might use our mobile applications to push
notifications to you informing you of game updates, high scores, and other 
service-related alerts that might be significant to you. By turning them off at the 
device level through your settings, you can choose to stop receiving these types 
of communications at any time.</p>
                  <p>Through our Service, we may obtain non-personal information about you. The 
type and version of your operating system, as well as the time and date of your 
visit to the Service, may be included in this information.</p>
                  <h3>Data gathered from additional sources</h3>
                  <p>If you access the App through a third-party site or platform, such as Facebook, 
Apple Game Centre, or Google Sign-In, we may access information about you 
from that site or platform, including your screen name, profile details, and friend 
lists, in accordance with the authorization procedures established by that third-
party site or platform, provided that you have granted that third-party site's 
request for the information.</p>
                  <p>If you access the App through a third-party site or platform, you represent and warrant that 
                    (i) you will abide by the terms and policies of that site or platform when accessing and using those features, and (ii) you are older than the legal minimum age requirement set by the laws of the various jurisdictions for that third-party site or platform.</p>
                  <h3>Use of information: intent and legal support.</h3>
                  <p>We use information about you for the following purposes in accordance with the legal bases for each type of personal data as described below:</p>
                  <ul>
                    <li>to provide and deliver the products and services you request and send you related information as requested by you / as agreed with you;</li>
                    <li>to provide and maintain the App and the game experience; and</li>
                    <li>to send you technical notices, updates, security alerts, and support and administrative messages;</li>
                    <li>to provide news and information about the App that we think will be of interest to you;</li>
                    <li>to personalize and improve the App and provide tailored content and features;</li>
                    <li>to monitor and analyze trends, usage and activities in connection with the App; and</li>
                    <li>to provide children (as this term is construed under GDPR in EU, COPPA in the US and relevant applicable legislation in other jurisdictions) with reasonable contextual advertisements in the App.</li>
                    <li>to provide non-child users that have given us consent to share their advertising IDs with our ad network partners (as further specified under Sharing of Information) for the purpose of serving them with personalized advertisement in the App (behavioral advertising).</li>
                  </ul>
                  <h3>Information shared with Third Parties</h3>
                  <p>We may share information we collect or derive with third parties for the following purposes:</p>
                  <ul>
                    <li>With service providers that perform services on our behalf, including the ones referenced above under “Third Party Services”;</li>
                    <li>With our affiliates;</li>
                    <li>For analytics and research purposes;</li>
                    <li>In response to subpoenas, court orders, or other legal processes;</li>
                    <li>To protect our or a third party’s legal rights, investigate fraud, or to defend against a legal claim;</li>
                    <li>As part of a business transfer, such as a merger, acquisition, or bankruptcy;</li>
                    <li>We may share aggregate or anonymous information about you with advertisers, publishers, business partners, sponsors, and other third parties; and</li>
                    <li>We may use analytics services provided by third parties, including Google Analytics, Facebook Analytics, and GameAnalytics. These service providers may use cookies to collect information about your online activities across this and other sites over time for non-advertising purposes.</li>
                  </ul>
                  <h3>Data we collect from our third-party partners.</h3>
                  <p>To operate and manage our services we need to use third party service providers from time to time. A list of those parties can be found in this privacy policy. The type of information we might collect from them includes:</p>
                  <ul>
                    <li>Data we receive if you link a third-party tool with the Service (such as logging in via Facebook, or Google);</li>
                    <li>Demographic data (to determine the coarse location of your IP address)</li>
                    <li>Data from platforms that the games run on; and</li>
                    <li>Data for advertising and analytics purposes.</li>
                  </ul>
                  <h3>Information Disclosure and Use</h3>
                  <p>
                  Send you newsletters, promotions, or marketing materials, as well as any products, services, or functionality you have requested. We'll also reply to any questions you've submitted through our customer service features. You will have the option to stop receiving such materials in the future from us in the future. Following the guidelines in this privacy policy, you can also choose not to receive such materials at any time.
The names of some of the third-party analytics and advertising providers we utilize are listed below. For additional information about the privacy practices of our third-party service providers, please click on the links below
                  </p>
                  <ul>
                    <li style={listItemStyles}><span style={bulletPointStyles}>•</span><a style={linkStyles} target="_blank" href="https://www.google.com/intl/en/policies/privacy/" rel="noreferrer">Google Analytics</a></li>
                    <li style={listItemStyles}><span style={bulletPointStyles}>•</span><a style={linkStyles} target="_blank" href="https://www.google.com/policies/privacy/" rel="noreferrer">Firebase</a></li>
                    <li style={listItemStyles}><span style={bulletPointStyles}>•</span><a style={linkStyles} target="_blank" href="http://www.adcolony.com/privacy-policy/" rel="noreferrer">AdColony</a></li>
                    <li style={listItemStyles}><span style={bulletPointStyles}>•</span><a style={linkStyles} target="_blank" href="https://gameanalytics.com/privacy/" rel="noreferrer">Game Analytics</a></li>
                    <li style={listItemStyles}><span style={bulletPointStyles}>•</span><a style={linkStyles} target="_blank" href="https://www.applovin.com/privacy/" rel="noreferrer">Applovin</a></li>
                    <li style={listItemStyles}><span style={bulletPointStyles}>•</span><a style={linkStyles} target="_blank" href="https://www.google.com/policies/privacy" rel="noreferrer">Admob</a></li>
                    <li style={listItemStyles}><span style={bulletPointStyles}>•</span><a style={linkStyles} target="_blank" href="https://unity3d.com/legal/privacy-policy" rel="noreferrer">Unity Ads</a></li>
                    <li style={listItemStyles}><span style={bulletPointStyles}>•</span><a style={linkStyles} target="_blank" href="https://support.chartboost.com/en/legal/privacy-policy" rel="noreferrer">Chartboost</a></li>
                  </ul>
                  <h3>Security Safeguards</h3>
                  <p>We take reasonable precautions to guard your information from loss, misuse, and unauthorized access by other parties. Although we try our best to store the data gathered through the Service in a secure environment that is not accessible to the public, we cannot ensure the complete security of that data while it is being transmitted or while it is being stored on our systems. Additionally, we make an effort to maintain the reliability and security of our network and systems.</p>
                  <p>We are unable to guarantee that our security measures will stop unauthorized access to this information by third parties (sometimes known as "hackers"). We make no promises or representations regarding the security of your data against theft, unauthorized access, or modification by outside parties. However, no technique of electronic storage or transmission via the Internet is completely secure. As a result, we are unable to ensure its complete security.</p>
                  <h3>Child Policy at Our Company</h3>
                  <p>Anyone under the age of 16 is not deliberately solicited for their personal information by us, nor are they knowingly permitted to use our services. Please do not provide us with any personal information about you, including your name, address, phone number, or email address, if you are under the age of 16. Underage users are not permitted to submit any personal information. If we come to know that we have obtained personal information from a child under 16, we shall take all reasonable steps to remove that data. Please get in touch with us if you think we might have any information from or about a kid under the age of 16.</p>
                </div>
              </article>


            </div>
          </div>
          <div class="clr"></div>
        </div>
      </div>

      <div id="footer">
        <div class="links_footer">
          <a href="https://desertgamestudio.com/privacy-policy/">
            Privacy Policy
          </a>
          <a href="https://desertgamestudio.com/privacy-policy/">
            Terms of Service
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
