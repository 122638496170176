import React from "react";
import TopHeader from "../components/TopHeader";
import Footer from "../components/Footer";

const TermsAndService = () => {
  return (
    <>
      <TopHeader />
      <div className="section featured-game" style={{backGroundColor:'white',zIndex:10}}>
        <style type="text/css"></style>
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <h1 class="entry__title">Privacy Policy</h1>

              <article
                id="post-17"
                class="entry post-17 page type-page status-publish hentry"
              >
                <div class="entry__content">
                  <p>
                    IMPORTANT – READ CAREFULLY: This Terms of Use (“
                    <strong>Terms</strong>“) is a legal agreement between you
                    and Desert Game Studio (“<strong>Desert Game Studio</strong>
                    “) and governs the use by “you” while you play Desert Game
                    Studio’s games (“<strong>Games</strong>“) and/or use Desert
                    Game Studio’s websites that link to this Privacy Policy (“
                    <strong>Websites”</strong>) (collectively, the “
                    <strong>Services</strong>“). By using the Services you agree
                    to these Terms. If you do not agree to these Terms, do not
                    purchase or use the Services.
                  </p>
                  <p>
                    <strong>
                      BY USING THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ
                      THIS AGREEMENT AND AGREE TO BE BOUND BY ITS TERMS AND
                      CONDITIONS.
                    </strong>
                  </p>
                  <p>
                    PLEASE REVIEW Desert Game Studio’S{" "}
                    <a href="https://www.desertgamestudio.com//privacy-policy/">
                      PRIVACY POLICY
                    </a>
                    , WHICH IS INCORPORATED INTO THESE TERMS BY REFERENCE. BY
                    ACCEPTING THESE TERMS, YOU ARE ALSO ACCEPTING THE PRIVACY
                    POLICY.
                  </p>
                  <ol>
                    <li>
                      <h3>
                        <strong> License and restrictions.</strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    Subject to these Terms, Desert Game Studio grants you a
                    non-exclusive, limited, revocable, non-transferable license
                    to use the Services for your personal, non-commercial use.
                    You may not: (a) modify, translate, reverse-engineer,
                    reverse-compile or decompile, disassemble, create derivative
                    works of, or use data gathering or extraction tools in
                    connection with the Services; (b) reproduce, duplicate,
                    copy, sell, resell, or otherwise exploit the Services, in
                    whole or in part, for any purpose without Desert Game
                    Studio’s express written consent; (c) frame or utilize
                    framing techniques to enclose any trademark, logo, or other
                    proprietary information (including images, text, page
                    layout, or form) without the express written consent of the
                    respective owner; (d) use any data mining tools or
                    automation tools such as spiders, crawlers, scripts, bots,
                    or any automated method of recording information; (e) use
                    any meta-tags or any other “hidden text” utilizing “Desert
                    Game Studio,” its affiliates, partners or artists without
                    Desert Game Studio’s express written permission; or (f)
                    engage in any conduct in connection with the Services that
                    may be considered illegal or tortious including, without
                    limitation, “hacking” the Services, or infringing the
                    intellectual property or other proprietary rights of Desert
                    Game Studio or third-parties.
                  </p>
                  <p>
                    Additionally, where the Services are a downloadable game
                    and/or application (“<strong>App</strong>“), Desert Game
                    Studio grants you a non-exclusive, non-transferable,
                    revocable, limited license to use the App for your personal,
                    non-commercial use on authorized devices, unless otherwise
                    specified. The App may not be copied, resold, or
                    sublicensed. These Terms apply to all versions and updates
                    to the App, including, without limitation, free and paid
                    versions.
                  </p>
                  <p>
                    If you <strong>don’t</strong> receive a pop-up that asks for
                    your age when you first access the game, then you must be at
                    least 16 years old to access the Game. If you{" "}
                    <strong>do</strong> receive a pop-up that asks for your age
                    when you first access the game or are accessing the Website,
                    then any age user may access the Game and Website. As
                    described in our Privacy Policy, we will either provide a
                    version of that Service that does not collect, use, or
                    disclose personal information, except as permitted by
                    applicable data protection laws, including without
                    limitation the Children’s Online Privacy Protection Act, or
                    obtain legally valid parental consent.
                  </p>
                  <p>
                    The App may include measures to control access to the App
                    (including age controls), prevent unauthorized copies, or
                    otherwise attempt to prevent anyone from exceeding the
                    limited rights and licenses granted under these Terms. You
                    may not take any action to circumvent or defeat such
                    security features. If you disable or otherwise tamper with
                    the technical protection measures, your license to use the
                    App or other Services shall be considered automatically
                    revoked.
                  </p>
                  <ol start="2">
                    <li>
                      <h3>
                        <strong>
                          {" "}
                          Third-party product, services and links.
                        </strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    Desert Game Studio may offer third-party products through
                    the Services. You assume all risks and liabilities
                    associated with the use of any such third-party products.
                    Additionally, your rights and obligations regarding such
                    products or services will be governed by the separate terms
                    of use and privacy policies of the applicable third-party
                    provider.
                  </p>
                  <p>
                    The Services may include hyperlinks to other websites over
                    which Desert Game Studio has no control. Desert Game Studio
                    is not responsible for the availability of such external
                    sites or resources and will not be responsible or liable for
                    any content, advertising, products, or other materials on or
                    available from such third-party websites or resources.
                  </p>
                  <ol start="3">
                    <li>
                      <h3>
                        <strong> Access to the services.</strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    By using the Services, you affirm that you are at least 18
                    years of age (or have reached the age of majority if that is
                    not 18 years of age where you live) or that you have
                    reviewed these Terms with your parent or guardian and he or
                    she assents to these Terms on your behalf and takes full
                    responsibility for your compliance with them. If you are
                    under the age of 13, you must have permission from your
                    parent or guardian in order to use the Services. If you do
                    not have permission from your parent of guardian, certain
                    features and functionality of the Services may be disabled.
                    Information regarding use of your personal information can
                    be found in Desert Game Studio’s{" "}
                    <a href="https://www.desertgamestudio.com//privacy-policy/">
                      Privacy Policy
                    </a>{" "}
                    or in the privacy policy specific to the Service you are
                    using.
                  </p>
                  <p>
                    You may be required to register an account before using
                    certain Services (“<strong>Account</strong>“). You may also
                    be required or have the option to access the Services
                    through a social networking service (“<strong>SNS</strong>
                    “). Your Account is personal to you, and you may not rent,
                    lease, sell, trade, gift, bequeath, or otherwise transfer it
                    to anyone else. You agree to provide true and complete
                    information about yourself when you register your Account
                    and to keep it up to date. You may not have more than one
                    Account or access the Services through more than one SNS at
                    any given time. You are solely responsible for maintaining
                    the confidentiality of your password and user name and for
                    any activities that occur under your Account. Desert Game
                    Studio does not authorize use of automated methods to sign
                    up for an Account, and Desert Game Studio reserves the right
                    to immediately terminate your access to the Services or your
                    Account if you breach these Terms or otherwise violate any
                    applicable law, rule, or regulation in your jurisdiction.
                  </p>
                  <p>
                    You are responsible for the internet connection and/or
                    mobile charges that you may incur for accessing and/or using
                    the Services.
                  </p>
                  <ol start="4">
                    <li>
                      <h3>
                        <strong> In-app purchases.</strong>
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <strong>
                          {" "}
                          In-game Virtual Currency and Virtual Items
                        </strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    The Service may include an opportunity for you to earn, buy
                    or otherwise obtain in-game currency (“
                    <strong>Virtual Currency</strong>“) to access virtual,
                    in-game digital goods or items (“
                    <strong>Virtual Items</strong>“). Methods of obtaining
                    Virtual Currency are at Desert Game Studio’s sole
                    discretion. Virtual Currency and Virtual Items are not real
                    currency, do not have monetary value, and may not be
                    redeemed for legal currency, or items of value outside of
                    the Services.
                  </p>
                  <p>
                    Virtual Currency and Virtual Items obtained via the Service
                    are provided to you under a limited, personal, revocable,
                    non-transferable, non-sublicenseable license to use within
                    the Service. You have no property interest, right or title
                    in or to any Virtual Currency or Virtual Items appearing or
                    originating in the Service, and they may not be transferred
                    or resold in any manner, including, without limitation, by
                    means of any direct sale or auction service.
                  </p>
                  <p>
                    Desert Game Studio has no liability for hacking or loss of
                    your Virtual Currency or Virtual Items or any goods or
                    services obtained via Virtual Currency. Desert Game Studio
                    has no obligation to, and will not, reimburse you for any
                    Virtual Currency or Virtual Items obtained via Virtual
                    Currency. Desert Game Studio reserves the right, in its sole
                    discretion and without prior notification, to change or
                    limit the price, availability, or order quantity of any
                    Virtual Currency or Virtual Items and to refuse to provide
                    you with any Virtual Currency or Virtual Items.
                  </p>
                  <p>
                    Virtual Currency may have an expiration date. If your
                    Virtual Currency or Virtual Items expire and you still have
                    an active Account, Desert Game Studio may offer conversion
                    of such Virtual Currency into Virtual Items for you in its
                    sole discretion, however Desert Game Studio is under no
                    obligation to do so. Virtual Currency and Virtual Items may
                    be forfeited if: (a) your Account or access to the Services
                    is terminated or suspended for any reason; (b) you breach
                    these Terms; (c) Desert Game Studio discontinues
                    availability of the Service; or (d) you disassociate from
                    the Service.
                  </p>
                  <ol>
                    <li>
                      <h3>
                        <strong> Subscriptions</strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    In some of our games, you may pay a fee for a subscription
                    membership (“<strong>Subscription</strong>“).
                  </p>
                  <p>
                    <strong>
                      <em>
                        When you buy a Subscription, you agree to the following:
                      </em>
                    </strong>
                  </p>
                  <ul>
                    <li>
                      A Subscription fee will be charged to your chosen payment
                      method in the app store of your device’s platform (“
                      <strong>App Store</strong>“). You will be charged the
                      Subscription fee displayed at the time of sale; and
                    </li>
                    <li>
                      Your Subscription will automatically renew for the
                      applicable length of your Subscription until either you or
                      Desert Game Studio cancels your Subscription. By way of
                      example, if you buy a 3-month Subscription, the
                      Subscription will automatically renew for another 3-month
                      period (unless either you or Desert Game Studio cancels
                      your Subscription).
                    </li>
                  </ul>
                  <p>
                    <strong>
                      <em>Canceling Subscriptions:</em>
                    </strong>
                  </p>
                  <p>
                    To stop automatic renewal of your Subscription and automatic
                    withdrawal of Subscription fees, you must cancel your
                    Subscription within 24 hours before it renews for the length
                    of the next Subscription period. You may cancel your
                    Subscription at any time by managing the subscription
                    settings at your App Store.
                  </p>
                  <ul>
                    <li>
                      For the Google Play store, instructions for canceling your
                      Subscription may be found here:{" "}
                      <a href="https://support.google.com/googleplay/answer/7018481">
                        https://support.google.com/googleplay/answer/7018481
                      </a>
                    </li>
                    <li>
                      For the Apple App store, instructions for canceling your
                      Subscription may be found here:{" "}
                      <a href="https://support.apple.com/en-us/HT202039">
                        https://support.apple.com/en-us/HT202039
                      </a>
                    </li>
                  </ul>
                  <p>
                    <strong>
                      <em>Other Subscription Terms:</em>
                    </strong>
                  </p>
                  <p>
                    To receive any daily rewards as part of your Subscription,
                    you must open the game that you have the Subscription for.
                    You will not receive rewards for the days on which you did
                    not open the game.
                  </p>
                  <ol>
                    <li>
                      <h3>
                        <strong>
                          {" "}
                          Payments for Subscriptions, Virtual Currencies, and
                          Virtual Items
                        </strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    When you pay for a Subscription, Virtual Currencies, and/or
                    Virtual Items, all payments are charged and processed by the
                    App Store (e.g. the Google Play store or the Apple App
                    store), so please review your app store’s payment terms for
                    additional payment terms that may apply.
                  </p>
                  <p>
                    You agree that all payments for Virtual Currency, Virtual
                    Items, and Subscriptions are final. No refunds will be
                    given, except in Desert Game Studio’s sole and absolute
                    discretion.
                  </p>
                  <ol start="5">
                    <li>
                      <h3>
                        <strong> Online content and conduct.</strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    The Services may include opportunities, through your Account
                    or SNS, to chat or participate in blogs, message boards,
                    online forums and other functions that provide you with the
                    opportunity to create, submit, post, display, transmit,
                    perform, publish, or distribute to the world (“
                    <strong>Forum(s)</strong>“) links, writings, photos,
                    graphics, or other material (“<strong>Forum Content</strong>
                    “). All Forum Content posted by you to any Forum shall be
                    your sole responsibility.
                  </p>
                  <p>
                    The Forums shall be used only in a noncommercial manner.
                    Desert Game Studio has the right, but not the
                    responsibility, to monitor or remove Forum Content that
                    Desert Game Studio believes to be harmful, offensive, or
                    otherwise violates these Terms, without liability to you.
                    Desert Game Studio in its sole discretion, reserves the
                    right to restrict or disable your use of the Forums.
                  </p>
                  <p>
                    By submitting Forum Content to any Forum, you warrant that
                    you have all necessary rights to submit the Forum Content
                    without any restrictions of any kind, and grant to Desert
                    Game Studio a fully paid, royalty-free, non-exclusive,
                    irrevocable, worldwide, unconditional, perpetual, right and
                    license to use, reproduce, modify, publically display and
                    perform, make derivatives works of, sublicense, and
                    distribute all such Forum Content in all media now known or
                    later developed, and/or to incorporate such materials in
                    other works in any form, media, or technology now known or
                    later developed.
                  </p>
                  <p>
                    Any opinions, advice, statements, services, offers or other
                    information or content expressed or made available by
                    third-parties in the Forums, are those of the respective
                    authors and not of Desert Game Studio or its affiliates, and
                    Desert Game Studio is not responsible for the accuracy or
                    reliability of same. Desert Game Studio makes no
                    representations or warranties regarding the Forums.
                  </p>
                  <p>
                    All Forum Content posted by you to any Forum shall be your
                    sole responsibility. You may not:
                  </p>
                  <ul>
                    <li>
                      a) Use the Services of Forums in any manner which violates
                      applicable laws or regulations;
                    </li>
                    <li>
                      b) Post or transmit any Forum Content that: (i) violates a
                      third party’s rights or dignity, including any contract,
                      privacy, publicity or intellectual property rights; (ii)
                      is deceptive, untrue, harassing, libelous, defamatory,
                      abusive, tortious, threatening, harmful, obscene,
                      indecent, hateful, bigoted, racist or pornographic; (iii)
                      would constitute or encourage a criminal offense; (iv)
                      contains any viruses, worms or similar software; or (v)
                      includes personal information about another person without
                      that person’s consent;
                    </li>
                    <li>
                      c) Impersonate or falsely state or represent your
                      identity;
                    </li>
                    <li>
                      d) Engage in or facilitate the transmission of unsolicited
                      mass mailings, or “spamming”;
                    </li>
                    <li>
                      e) Distribute or otherwise publish any material containing
                      any solicitation, promotion or advertising for goods or
                      services;
                    </li>
                    <li>
                      f) Collect personal data about others for unlawful,
                      commercial, or any other unauthorized purposes;
                    </li>
                    <li>
                      g) Access, or attempt to gain access to, another user’s
                      account without authorization;
                    </li>
                    <li>
                      h) Promote, encourage, or take part in any activity
                      involving hacking, phishing, taking advantage of exploits
                      or cheats and/or distribution of counterfeit software
                      and/or counterfeit virtual currency/items;
                    </li>
                    <li>
                      i) Use any game hacking/altering/cheating software or
                      tools;
                    </li>
                    <li>
                      j) Upload files that contain a virus, worm, spyware, time
                      bombs, corrupted data or other computer programs that may
                      damage, or otherwise interfere with the Services; or
                    </li>
                    <li>
                      k) Reverse engineer, decompile, or modify, or attempt to
                      modify, any part of the Service.
                    </li>
                  </ul>
                  <p>
                    You understand that you may be exposed to Forum Content and
                    actions from other users that are indecent, explicit,
                    offensive, or which otherwise violates these Terms (“
                    <strong>Inappropriate Activity</strong>“). You can report
                    actual or suspected Inappropriate Activity to{" "}
                    <a href="mailto:info@desertgamestudio.com">
                      info@desertgamestudio.com
                    </a>
                    . Desert Game Studio reserves the right, in its sole
                    discretion, to take any action against Inappropriate
                    Activity that Desert Game Studio believes to be harmful,
                    offensive, or otherwise violates these Terms. You agree that
                    Desert Game Studio will not be liable to you or any
                    third-party in any way for Forum Content, Inappropriate
                    Activity, or for any damage or loss therefrom.
                  </p>
                  <ol start="6">
                    <li>
                      <h3>
                        <strong> Title.</strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    Unless otherwise specified in these Terms, any and all
                    title, ownership, rights, and intellectual property rights
                    in and to the Services including all content therein
                    (collectively the “
                    <strong>Desert Game Studio Content</strong>“) shall remain
                    in Desert Game Studio and/or its suppliers, and are
                    protected by the copyright laws of the United Kingdom, the
                    United States and other jurisdictions around the world via
                    international copyright treaties. The names and logos, and
                    other graphics, icons, and service names associated with the
                    Desert Game Studio Content are trademarks, registered
                    trademarks or trade dress of Desert Game Studio or its
                    licensors. You may not use, copy, transmit, modify,
                    distribute, or create any derivative works from the Services
                    or the Desert Game Studio Content without prior written
                    consent from Desert Game Studio.
                  </p>
                  <p>
                    The Services may allow you to create content (“
                    <strong>Your Content</strong>“) that incorporates the Desert
                    Game Studio Content, including, but not limited, to photos
                    incorporating elements or graphics from games, screenshots
                    or a video of your game play. Desert Game Studio retains all
                    rights, title and interest in and to the Desert Game Studio
                    Content. You retain all rights to Your Content; provided,
                    however, that Desert Game Studio shall have an exclusive,
                    perpetual, irrevocable, fully transferable and
                    sub-licensable worldwide right and license to Your Content
                    for any purpose, including, but not limited to, the rights
                    to reproduce, copy, adapt, modify, perform, display,
                    publish, broadcast, transmit, or otherwise communicate to
                    the public by any means whether now known or unknown and
                    distribute Your Content without any further notice or
                    compensation to you. Except where prohibited by law, you
                    hereby waive any moral rights of paternity, publication,
                    reputation, or attribution with respect to Desert Game
                    Studio’s use of Your Content.
                  </p>
                  <ol start="7">
                    <li>
                      <h3>
                        <strong>
                          {" "}
                          Disclaimer of warranty and limit of liability.
                        </strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    Desert Game Studio MAKES NO REPRESENTATION, WARRANTY, AND/OR
                    GUARANTEE OF THE SUITABILITY OF THE SERVICES FOR YOUR
                    PURPOSES, OR THAT THE USE OF THE SAME WILL BE SECURE,
                    UNINTERRUPTED, ERROR-FREE, FREE OF VIRUSES OR OTHER HARMFUL
                    COMPONENTS, OR FUNCTION PROPERLY IN COMBINATION WITH ANY
                    THIRD-PARTY TECHNOLOGY, HARDWARE, SOFTWARE, SYSTEMS OR DATA.
                  </p>
                  <p>
                    THE SERVICES ARE PROVIDED “AS IS” AND ALL CONDITIONS,
                    REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED,
                    STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY
                    IMPLIED WARRANTY OF MERCHANTABILITY, TITLE, FITNESS FOR A
                    PARTICULAR PURPOSE, OR NON-INFRINGEMENT ARE HEREBY
                    DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
                    LAW. FURTHER, Desert Game Studio MAKES NO REPRESENTATIONS OR
                    WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
                    OPERATION OF THE SAME, OR TO THE INFORMATION, CONTENT,
                    COMMUNICATIONS, MATERIALS, OR PRODUCTS AND SERVICE CONTAINED
                    THEREIN. YOUR USE OF THE Desert Game Studio SERVICES IS AT
                    YOUR SOLE RISK.
                  </p>
                  <p>
                    Desert Game Studio WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY
                    KIND ARISING FROM YOUR USE OF THE SERVICES, INCLUDING,
                    WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE
                    AND CONSEQUENTIAL DAMAGES. CERTAIN REGIONAL LAWS DO NOT
                    ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
                    LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU,
                    SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR
                    LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
                    ADDITIONAL RIGHTS.
                  </p>
                  <p>
                    YOU FURTHER ACKNOWLEDGE THAT Desert Game Studio IS NOT
                    LIABLE, AND YOU AGREE NOT TO HOLD Desert Game Studio LIABLE,
                    FOR THE CONDUCT OF THIRD-PARTIES, INCLUDING OTHER USERS OF
                    THE SERVICES AND OTHER THIRD-PARTY SITES, AND THAT THE RISK
                    OF USING OR ACCESSING THE SERVICES, AND OF INJURY FROM THE
                    FOREGOING, RESTS ENTIRELY WITH YOU.
                  </p>
                  <ol start="8">
                    <li>
                      <h3>
                        <strong> Indemnification.</strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    You agree to hold harmless, indemnify and defend Desert Game
                    Studio, its licensors, affiliates and suppliers and their
                    respective officers, directors and employees, from and
                    against any losses, damages, fines and expenses (including
                    attorneys’ fees and costs) due to, arising out of, or
                    relating to any information or item, including, without
                    limitation, Your Content, Forum Content, and/or
                    Inappropriate Content you may submit, post, transmit or make
                    available through the Forums or Services, your use of the
                    Forums and Services, your connection to the Forums and
                    Services, your violation of these Terms, or your violation
                    of any law, regulation or third-party right. Without
                    limiting your indemnification obligations described herein,
                    Desert Game Studio reserves the right, at its own expense,
                    to assume the exclusive defense and control of any matter
                    subject to indemnification by you.
                  </p>
                  <ol start="9">
                    <li>
                      <h3>
                        <strong> Termination.</strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    These Terms will automatically terminate if you fail to
                    comply with any term hereof. You and Desert Game Studio have
                    the right to terminate or cancel your Account, at any time
                    for any reason and without notice. Upon termination you
                    shall immediately discontinue use of the Services. Your
                    obligations under Sections 5, 6, 11, 13, and 14 shall
                    survive any termination.
                  </p>
                  <ol start="10">
                    <li>
                      <h3>
                        <strong> No assignment.</strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    These Terms and the licenses granted to you herein are
                    personal to you, and may not be assigned prior to obtaining
                    Desert Game Studio’s express written consent.
                  </p>
                  <ol start="11">
                    <li>
                      <h3>
                        <strong> Arbitration and dispute resolution.</strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    These Terms and all disputes and claims relating in any way
                    to, or arising out of, these Terms, the Services, the
                    Forums, Forum Content, Inappropriate Content, Your Content,
                    or your use of the Software or Forums shall be governed by
                    the laws of England and Wales. You and Desert Game Studio
                    agree that the exclusive remedy for all disputes and claims
                    relating in any way to, or arising out of, these Terms, the
                    Services, the Forums, or your use of the Services or Forums
                    shall be final and binding arbitration. The arbitration
                    shall be conducted under the rules of the ICC International
                    Court of Arbitration (“<strong>ICA</strong>“). To the
                    fullest extent permitted by law: (a) no arbitration under
                    these Terms shall be joined to any other arbitration; (b) no
                    class arbitration proceedings shall be permitted; (c) no
                    finding or stipulation of fact in any other arbitration,
                    judicial or similar proceeding may be given preclusive or
                    collateral estoppel effect in any arbitration hereunder
                    (unless determined in another proceeding between you and
                    Desert Game Studio); and (d) no conclusion of law in any
                    other arbitration may be given any weight in any arbitration
                    hereunder (unless determined in another proceeding between
                    you and Desert Game Studio). You and Desert Game Studio must
                    commence an arbitration by filing a demand for arbitration
                    with the ICA within ONE (1) YEAR after the date the party
                    asserting the claim first knows or reasonably should know of
                    the act, omission or default giving rise to the claim; and
                    there shall be no right to any remedy for any claim not
                    asserted within that time period (if applicable law
                    prohibits a one year limitations period for asserting
                    claims, the claim must be asserted within the shortest time
                    period in excess of one year that is permitted by applicable
                    law). Both parties will be responsible for their respective
                    arbitration fees.
                  </p>
                  <ol start="12">
                    <li>
                      <h3>
                        <strong> Infringement notification.</strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    Any claims related to the Services should be addressed to:
                  </p>
                  <p>
                    Desert Game Studio
                    <br />
                    Office # 807, Al-Hafeez Executive, Firdous Mkt Rd, 30-C/3
                    Block C 3 Gulberg III, Lahore, Punjab 54200
                  </p>
                  <p>Telephone: +923059192811</p>
                  <p>
                    Email:{" "}
                    <a href="mailto:info@desertgamestudio.com">
                      info@desertgamestudio.com
                    </a>
                  </p>
                  <ol start="13">
                    <li>
                      <h3>
                        <strong> Feedback.</strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    Desert Game Studio does not accept unsolicited feedback,
                    suggestions or other information about the Services or other
                    aspects of its business. If you provide Desert Game Studio
                    with feedback or other ideas, you agree that Desert Game
                    Studio is free to use any feedback provided by you for any
                    purpose (without compensation to you), including, without
                    limitation, improving its products and services and creating
                    derivative products.
                  </p>
                  <ol start="14">
                    <li>
                      <h3>
                        <strong> General.</strong>
                      </h3>
                    </li>
                  </ol>
                  <p>
                    These Terms, along with the{" "}
                    <a href="https://desertgamestudio.com/privacy-policy/">
                      Privacy Policy
                    </a>
                    , and any other privacy policy applicable to the specific
                    Services you are using, constitute the complete and final
                    agreement between Desert Game Studio and you with respect to
                    your use of the Services and may not be modified by you
                    except in a writing duly signed by you and an authorized
                    representative of Desert Game Studio. You agree that your
                    breach of these Terms will cause Desert Game Studio
                    immediate and irreparable harm and Desert Game Studio will
                    be entitled, notwithstanding the arbitration clause set
                    forth in Section 11 above, to seek injunctive relief without
                    the necessity of posting bond. Desert Game Studio and you
                    are independent parties and nothing in these Terms creates
                    an employment or agent relationship. If any provision herein
                    is held to be unenforceable, such provision shall be
                    reformed in that matter only to the extent necessary to make
                    it enforceable, and shall not affect the enforceability of
                    the remaining provisions. The failure of either party to
                    enforce any right or provision in these Terms will not
                    constitute a waiver of such right or provision.
                  </p>
                  <p>
                    Desert Game Studio may make changes to the Services, these
                    Terms, or its{" "}
                    <a href="https://desertgamestudio.com/privacy-policy/">
                      Privacy Policy
                    </a>{" "}
                    from time to time. Desert Game Studio will use reasonable
                    efforts to provide you with notice of any material changes.
                    All such changes will become effective immediately upon
                    notice and/or posting. If any of these changes are deemed
                    invalid, void, or for any reason unenforceable, that change
                    will be deemed severable and will not affect the validity
                    and enforceability of any unchanged portions of the
                    Services, these Terms, or the{" "}
                    <a href="https://desertgamestudio.com/privacy-policy/">
                      Privacy Policy
                    </a>
                    . Desert Game Studio may transfer or assign the Services,
                    these Terms, or the{" "}
                    <a href="https://desertgamestudio.com/privacy-policy/">
                      Privacy Policy
                    </a>
                    , in whole or in part, to any third-party of Desert Game
                    Studio’s choosing. These Terms will inure to the benefit of
                    and be binding upon Desert Game Studio’s respective
                    successors and assigns.
                  </p>
                  <h3>CONTACT US</h3>
                  <p>
                    If you have questions regarding these Terms, please contact
                    us at:
                  </p>
                  <p>
                    <strong>Desert Game Studio</strong>
                    <br />
                    <strong>Attn</strong>: Terms of Use
                    <br />
                    <strong>E-mail</strong>:{" "}
                    <a href="mailto:info@desertgamestudio.com">
                      info@desertgamestudio.com
                    </a>
                  </p>
                </div>
              </article>
            </div>
          </div>
          <div class="clr"></div>
        </div>
      </div>

      <div id="footer">
        <div class="links_footer">
          <a href="https://desertgamestudio.com/privacy-policy/">
            Privacy Policy
          </a>
          <a href="https://desertgamestudio.com/privacy-policy/">
            Terms of Service
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndService;
